<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Buy Test</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true" v-if="!loading">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            Buy Test
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-card>
        <ion-card-content>
          <ion-button @click="submit">Pay now!</ion-button>
        </ion-card-content>
      </ion-card>

    </ion-content>

    <ion-content v-if="loading" class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonButton } from '@ionic/vue';
import axios from 'axios';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Buy',
  data () {
    return {
      loading: true,
      stripeUrl: null
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardContent,
    IonButton
  },
  created() {
      this.finishedLoading = true;
  },
  mounted() {

    axios.get(process.env.VUE_APP_API_URL + '/create-checkout-session', {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
    .then(result => {
          console.log(result);
          this.loading = false;
          this.stripeUrl = result.data.sessionUrl;
    });

  },
  methods: {
    submit(){
        window.location.href = this.stripeUrl;
    }
  }
});

</script>

<style scoped>


</style>