
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonButton } from '@ionic/vue';
import axios from 'axios';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Buy',
  data () {
    return {
      loading: true,
      stripeUrl: null
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardContent,
    IonButton
  },
  created() {
      this.finishedLoading = true;
  },
  mounted() {

    axios.get(process.env.VUE_APP_API_URL + '/create-checkout-session', {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
    .then(result => {
          console.log(result);
          this.loading = false;
          this.stripeUrl = result.data.sessionUrl;
    });

  },
  methods: {
    submit(){
        window.location.href = this.stripeUrl;
    }
  }
});

